import React from 'react'
import JobForm from './form'
import './App.css'


// var sectionStyle = {
//     width: "100%",
//     height: "400px",
//     backgroundImage: "url(" + { Background } + ")"
//   };


const App =(props)=>{
    return (<div className="App">
        <JobForm/>
        {/* <img src="/bc3.png" /> */}
    </div>)
}

export default App
