import React,{useEffect, useState} from 'react'
import {
    TextField,Grid,MenuItem,InputLabel,Select,
    FormControl,makeStyles,Button,Card,CardContent,CardHeader,Typography,Link,Box,Snackbar,Checkbox} from '@material-ui/core'
import FileUpload from './FileUpload'
import { createMuiTheme } from '@material-ui/core/styles';
import Header from './Header'
import validator from 'validator'
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios'
import {FORM_SUBMISSION} from './constants'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import FileUpload1 from './FileUpload2'
// import FileUpload from './FileUpload'





function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.grssl.com/">
          Grassroots
        </Link>{' '}
        {2021}
        {'.'}
      </Typography>
    );
  }

const theme = createMuiTheme();

theme.typography.h6 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const JobForm =(props)=>{
    //declaring state variables for all the inputs
    const [First_Name,setFirst_Name] = useState("")
    const [Last_Name ,setLast_Name] = useState("")
    const [DOB,setDOB] = useState("")
    const [Blood_Group,setBlood_Group] = useState("")
    const [Maritial_Status,setMaritial_Status] = useState("")
    const [Sex,setSex] = useState("")
    const [Nationality,setNationality] = useState("")
    const [EmailID,setEmailID] = useState("")
    const [Residential_Address,setResidential_Address] = useState("")
    const [Permanent_Address,setPermanent_Address] = useState("")
    const [Alternate_Number,setAlternate_Number] = useState("")
    const [Pan_Number,setPan_Number] = useState("")
    const [Adhaar_Number,setAdhaar_Number] = useState("")
    const [Emergency_Contact_Number,setEmergency_Contact_Number] = useState("")
    const [Father_Name,setFather_Name] = useState("")
    const [Fathers_DOB,setFathers_DOB] = useState("")
    const [Mothers_Name,setMothers_Name] = useState("")
    const [Mothers_DOB,setMothers_DOB] = useState("")
    const [Process_Selected_for,setProcess_Selected_for] = useState("")
    const [Bank_Account_Number_for_Salary_Credit,setBank_Account_Number_for_Salary_Credit] = useState("")
    const [IFSC_Code,setIFSC_Code] = useState("")
    const [Bank_Name,setBank_Name] = useState("")
    const [PF_Number,setPF_Number] = useState("")
    const [UAN_Number,setUAN_Number] = useState("")
    const [pAd1,setPAd1]= useState("")
    const [pAd2,setPAd2]= useState("")
    const [pCity,setpCity] = useState("")
    const [pcode,setpcode] = useState("")

    const [rAd1,setrAd1]= useState("")
    const [rAd2,setrAd2]= useState("")
    const [rCity,setrCity] = useState("")
    const [rcode,setrcode] = useState("")

   
    const [ip,setIp] = useState("")
    const [Mobile_Number,setMobile_Number] = useState("")
    const [Emergency_Contact_Relationship,setEmergency_Contact_Relationship] =  useState("")
    const [formErrors, setFormErrors] = useState({})
    const errors = {}
    const [open, setOpen] = React.useState(false);
    const [declaration,setDeclaration] = useState(false)

    
    const [pancard,setpancard] = useState("")
    const [cheque,setcheque] = useState("")
    const [adhar,setadhar] = useState("")
    const [photo,setphoto] = useState("")
    const [payslip1,setPayslip1]= useState("")
    const [payslip2,setPayslip2]= useState("")
    const [payslip3,setPayslip3]= useState("")
    const [sslcMarksCard,setsslcmarkscard]= useState("")
    const [reliving,setreliving] = useState("")
    const [resume,setResume]= useState("")
    const [placement,setPlacement]= useState("")


    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({
      severity: '',
      message: ''
    });
   

  
    const onChangesetFirst_Name =(e)=>setFirst_Name(e.target.value.toUpperCase())
    const onChangesetLast_Name =(e)=>setLast_Name(e.target.value.toUpperCase())
    const onChangesetDOB =(e)=>setDOB(e.target.value)
    const onChangesetBlood_Group =(e)=>setBlood_Group(e.target.value.toUpperCase())
    const onChangesetSex =(e)=>setSex(e.target.value)
    const onChangesetNationality =(e)=>setNationality(e.target.value.toUpperCase())
    const onChangesetEmailID =(e)=>setEmailID(e.target.value)
    const onChangesetResidential_Address =(e)=>setResidential_Address(e.target.value.toUpperCase())
    const onChangesetPermanent_Address =(e)=>setPermanent_Address(e.target.value.toUpperCase())
    const onChangesetAlternate_Number =(e)=>setAlternate_Number(e.target.value)
    const onChangesetPan_Number =(e)=>setPan_Number(e.target.value.toUpperCase())
    const onChangesetAdhaar_Number =(e)=>setAdhaar_Number(e.target.value)
    const onChangesetEmergency_Contact_Number =(e)=>setEmergency_Contact_Number(e.target.value)
    const onChangesetFather_Name =(e)=>setFather_Name(e.target.value.toUpperCase())
    const onChangesetsetMothers_Name =(e)=>setMothers_Name(e.target.value.toUpperCase())
    const onChangesetFathers_DOB =(e)=>setFathers_DOB(e.target.value)
    const onChangesetMothers_DOB =(e)=>setMothers_DOB(e.target.value)
    const onChangesetProcess_Selected_for =(e)=>setProcess_Selected_for(e.target.value)
    const onChangesetBank_Account_Number_for_Salary_Credit =(e)=>setBank_Account_Number_for_Salary_Credit(e.target.value)
    const onChangesetIFSC_Code =(e)=>setIFSC_Code(e.target.value.toUpperCase())
    const onChangesetBank_Name =(e)=>setBank_Name(e.target.value.toUpperCase())
    const onChangesetPF_Number =(e)=>setPF_Number(e.target.value)
    const onChangesetUAN_Number =(e)=>setUAN_Number(e.target.value)
    const onChangesetpancard =(e)=>setpancard(e.target.value)
    const onChangesetcheque =(e)=>setcheque(e.target.value)
    const onChangesetadhar =(e)=>setadhar(e.target.value)
    const onChangesetphoto =(e)=>setphoto(e.target.value)
    const onChangesetMaritial_Status =(e)=>setMaritial_Status(e.target.value)
    const onChangesetMobile_Number =(e)=>setMobile_Number(e.target.value)
    const onChangesetEmergency_Contact_Relationship =(e)=>setEmergency_Contact_Relationship(e.target.value)
    
    const onChangesetsetPAd1 =(e)=>setPAd1(e.target.value.toUpperCase())
    const onChangesetPAd2 =(e)=>setPAd2(e.target.value.toUpperCase())
    const onChangesetsetpCity =(e)=>setpCity(e.target.value.toUpperCase())
    const onChangesetpcode =(e)=>setpcode(e.target.value.toUpperCase())

    const onChangesetsetrAd1 =(e)=>setrAd1(e.target.value.toUpperCase())
    const onChangesetrAd2 =(e)=>setrAd2(e.target.value.toUpperCase())
    const onChangesetsetrCity =(e)=>setrCity(e.target.value.toUpperCase())
    const onChangesetrcode =(e)=>setrcode(e.target.value.toUpperCase())
    const onChangePlacement =(e)=>setPlacement(e.target.value)
    
    const handledeclaration =(e)=> setDeclaration(!declaration)

    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };

    // array values for dropdowns
    const martialStatus = [{name : 'Single' , value:"Single"},{name : 'Married' , value:"Married"}]
    const gender = [{name : 'Male' , value:"Male"},{name : 'Female' , value:"Female"}]
    const relationship = [{name : 'Father' , value:"Father"},{name : 'Mother' , value:"Mother"},{name : 'Husband' , value:"Husband"},{name : 'Wife' , value:"Wife"}]
    const placementType = [{name : 'Fresher' , value:"Fresher"},{name : 'Experienced ' , value:"Experienced"},{name : 'Campus Placement' , value:"Campus"}]

    const runValidations=()=>{
      if(First_Name.trim().length === 0){
        errors.First_Name = '*first Name cannot be blank'
      }
      if(Last_Name.trim().length === 0){
        errors.Last_Name = '*last Name cannot be blank'
      }
      if(EmailID.trim().length === 0){
        errors.EmailID = '*email cannot be blank'
      }else if(!validator.isEmail(EmailID)) {
        errors.EmailID = 'invalid email format'
      }
      if(DOB.trim().length === 0){
        errors.DOB = '*DOB cannot be blank'
      }
      if(Mobile_Number.trim().length === 0){
        errors.Mobile_Number = '*mobile number cannot be blank'
      }else if (Mobile_Number.trim().length !== 10){
        errors.Mobile_Number = '*Invalid Mobile number'
      }
      if(Blood_Group.trim().length === 0){
        errors.Blood_Group = '*Kindly enter blood your blood group'
      }
      if(Maritial_Status.trim().length === 0){
        errors.Maritial_Status = '*please select Martial status'
      }
      if(Sex.trim().length === 0){
        errors.Sex = '*please select Gender'
      }
      if(Nationality.trim().length === 0){
        errors.Nationality = '*please enter nationality'
      }
      if(Alternate_Number.trim().length === 0){
        errors.Alternate_Number = '* please select reference'
      }else if (Mobile_Number.trim().length !== 10){
        errors.Mobile_Number = '*Invalid Mobile number'
      }
      if(Emergency_Contact_Number.trim().length === 0){
        errors.Emergency_Contact_Number = '* please enter emergency contact number'
      }else if(Emergency_Contact_Number === Mobile_Number){
        errors.Emergency_Contact_Number = '* primary & emergency contact number cannot be same'
      }else if(Emergency_Contact_Number === Alternate_Number){
        errors.Emergency_Contact_Number = '* Alternate  & emergency contact number cannot be same'
      }
      if(Father_Name.trim().length === 0){
        errors.Father_Name = '* please enter your father name'
      }
      if(Fathers_DOB.trim().length === 0){
        errors.Fathers_DOB = '* please select  DOB'
      }
      if(Mothers_Name.trim().length === 0){
        errors.Mothers_Name = '* please enter your mother name'
      }
      if(Mothers_DOB.trim().length === 0){
        errors.Mothers_DOB = '* please select your DOB'
      }
      if(Process_Selected_for.trim().length === 0){
        errors.Process_Selected_for = '* please enter offer id'
      }
      if(Bank_Account_Number_for_Salary_Credit.trim().length === 0 && placement === 'Experienced'){
        errors.Bank_Account_Number_for_Salary_Credit = '* please enter Account number'
      }
      if(IFSC_Code.trim().length === 0 && placement === 'Experienced' && placement === 'Experienced'){
        errors.IFSC_Code = '* please select IFSC code'
      }else if(IFSC_Code.trim().length !== 11 && placement === 'Experienced'){
        errors.IFSC_Code = '* please enter valid IFSC code'
      }
      if(Bank_Name.trim().length === 0 && placement === 'Experienced'){
        errors.Bank_Name = '* please select currentLocation'
      }
      if(pAd1.trim().length === 0){
        errors.pAd1 = '* please enter Address line 1'
      }
      if(pAd2.trim().length === 0){
        errors.pAd2 = '* please enter Address line 2'
      }
      if(pCity.trim().length === 0){
        errors.pCity = '* please enter city'
      }
      if(pcode.trim().length === 0){
        errors.pcode = '* please enter pincode'
      }
      if(rAd1.trim().length === 0){
        errors.rAd1 = '* please enter Address line 1'
      }
      if(rAd2.trim().length === 0){
        errors.rAd2 = '* please enter Address line 2'
      }
      if(rCity.trim().length === 0){
        errors.rCity = '* please enter city'
      }
      if(rcode.trim().length === 0){
        errors.rcode = '* please enter pincode'
      }
      if(Pan_Number.trim().length === 0 && placement === 'Experienced'){
        errors.Pan_Number = '* please enter pan number'
      }else if(Pan_Number.trim().length !== 10 && placement === 'Experienced'){
        errors.Pan_Number = '* please enter Valid pan number'
      }
      if(Adhaar_Number.trim().length === 0){
        errors.Adhaar_Number = '* please enter Adhar number'
      }else if(Adhaar_Number.trim().length !== 12){
        errors.Adhaar_Number = '* please enter valid Adhar number'
      }
      if(Bank_Name.trim().length === 0 && placement === 'Experienced'){
        errors.Bank_Name = '* please enter Bank name'
      }
      if(pancard.trim().length === 0 && placement === 'Experienced'){
        errors.pancard = '* please upload Pancard'
        alert(errors.pancard)
      }
      if(cheque.trim().length === 0 ){
        errors.cheque = '* please upload Adhar'
        alert(errors.cheque)
      }
      if(adhar.trim().length === 0 && placement === 'Experienced'){
        errors.adhar = '* please upload Cheque'
        alert(errors.adhar)
      }
      if(photo.trim().length === 0){
        errors.photo = '* please upload photo'
        alert(errors.photo)
      }
      if(payslip1.trim().length === 0 && placement === 'Experienced'){
        errors.payslip1 = '* please upload payslip1'
        alert(errors.payslip1)
      }
      if(payslip2.trim().length === 0 && placement === 'Experienced'){
        errors.payslip2 = '* please upload payslip2'
        alert(errors.payslip2)
      }
      if(payslip3.trim().length === 0 && placement === 'Experienced'){
        errors.payslip3 = '* please upload payslip3'
        alert(errors.payslip3)
      }
      if(sslcMarksCard.trim().length === 0 && placement === 'Experienced'){
        errors.sslcMarksCard = '* please upload sslcMarksCard'
        alert(errors.sslcMarksCard)
      }
      if(resume.trim().length === 0 ){
        errors.resume = '* please upload resume'
        alert(errors.resume)
      }
      if(reliving.trim().length === 0 && placement === 'Experienced'){
        errors.reliving = '* please upload reliving'
        alert(errors.reliving)
      }
      if(placement.trim().length === 0 ){
        errors.placement = '* please Select Placement Type'
        alert(errors.placement)
      }
      if(declaration === false){
        errors.declaration = '* kindly agree terms and condition'
        alert(errors.declaration)
      }
    }

  
    
   
   

    const handleReset =(e)=>{
      setFirst_Name("")
      setLast_Name("")
      setDOB("")
      setBlood_Group("")
      setMaritial_Status("")
      setSex("")
      setNationality("")
      setEmailID("")
      setResidential_Address("")
      setPermanent_Address("")
      setAlternate_Number("")
      setPan_Number("")
      setAdhaar_Number("")
      setEmergency_Contact_Number("")
      setFather_Name("")
      setFathers_DOB("")
      setMothers_Name("")
      setMothers_DOB("")
      setProcess_Selected_for("")
      setIFSC_Code("")
      setBank_Name("")
      setPF_Number("")
      setUAN_Number("")
      setPAd1("")
      setPAd2("")
      setpCity("")
      setpcode("")
      setrAd1("")
      setrAd2("")
      setrCity("")
      setrcode("")
      setMobile_Number("")
      setDeclaration(false)
      setPlacement("")
 
    }

    //getting possible ip
    const getIp =()=>{
      axios.get(`https://api.ipify.org`)
        .then((response)=>{
          setIp(response.data)
        })
        .catch((error)=>{
          console.log(error)
        })
    }

    

    //form submission to server
    const formSubmission =(data)=>{
      console.log(data)
      console.log(errors)

        axios.post(`${FORM_SUBMISSION}`,data)
        .then((response)=>{
          console.log(response)
          setOpen(false);
          setSnackbarMessage({
            severity: 'success',
            message: 'Information Submitted Successfully !'

          });
          setSnackbarOpen(true);
        })
        .catch((error)=>{
          console.log(error)
          setOpen(false);
          setSnackbarMessage({
            severity: 'error',
            message: 'Something went wrong. Please try again !'
          });
          setSnackbarOpen(true);
        })
    }


    const getfileName =(name,value)=>{
      // console.log(name[0].name)
      // console.log(value)
      // // setDisable(false)
      // console.log(name,"file name")
      if(value === 'Pan card'){
        setpancard(name)
      }
      if(value === 'Adhar card'){
        setcheque(name)
      }
      if(value === 'Cancelled cheque'){
        setadhar(name)
      }
      if(value === 'Photograph'){
        setphoto(name)
      }
      if(value === 'Sslc / Puc / Degree marks card'){
        setsslcmarkscard(name)
      }
      if(value === 'Pay slip month 1'){
        setPayslip1(name)
      }
      if(value === 'Pay slip month 2'){
        setPayslip2(name)
      }
      if(value === 'Pay slip month 3'){
        setPayslip3(name)
      }
      if(value === 'Relieving letter'){
        setreliving(name)
      }
      if(value === 'Resume'){
        setResume(name)
      }
    }
    
  

   
  
    // console.log(firstName)
    const handleSubmit = (e)=>{
      e.preventDefault()
        runValidations()
        console.log(errors)
       
       
       
        if(Object.keys(errors).length === 0){
          setFormErrors({})
            const data ={
              placement,
              First_Name,
              Last_Name,
              DOB,
              Blood_Group,
              Maritial_Status,
              Sex,
              Nationality,
              EmailID,
              Residential_Address : `${pAd1},${pAd2},${pCity},${pcode} `,
              Permanent_Address : `${rAd1},${rAd2},${rCity},${rcode} `,
              Alternate_Number,
              Emergency_Contact_Number,
              Father_Name,
              Mothers_Name,
              Mothers_DOB,
              Process_Selected_for,
              IFSC_Code,
              Bank_Name,
              PF_Number,
              UAN_Number,
              Emergency_Contact_Relationship,
              Mobile_Number,
              Fathers_DOB,
              pancard,
              cheque,
              adhar,
              photo,
              payslip1,
              payslip2,
              payslip3,
              sslcMarksCard,
              reliving,
              resume,
              Pan_Number,
              Adhaar_Number,
              Bank_Account_Number_for_Salary_Credit
            }
            // console.log(data)
            // data.firstName.toUpperCase()
         //       data.currentLocation.toUpperCase()
            // console.log(data)
            formSubmission(data)
            handleReset()
          }else {
            //console.log('form errors', errors)
            setFormErrors(errors)
            
          }
    }
    
    useEffect(()=>{
      getIp()

    },[])

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    card :{
        maxWidth: 1700,
        justifyContent: 'center',
        alignItems: 'center'
    },
    root3:{
        display : 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    root2: {
        height: '120vh'
      },
     
      form: {
        width: '100%',
        marginTop: theme.spacing(1)
      },
     
      submit: {
        margin: theme.spacing(3, 0, 2)
      },
      avatarWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      root1: {
        width: '100%',
        height : '120vh',
        '& > * + *': {
          marginTop: theme.spacing(2),
          display : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        },
      },
  }));


  const classes = useStyles();

  
    return(<div className={classes.root1}>
        <Header/>
        <Grid container spacing={3} direction="row">
        <Grid item xs={0} sm={1}></Grid>
        <Grid item xs={12} sm={9}>
        <Card className={classes.card}>
        <CardHeader
                title={
                  `Employee Joining Form`
                }
              />
        <CardContent > 
      <Grid container spacing={3} direction="row">
      <Grid item xs={12} sm={6} lg={3}></Grid>
      <Grid item xs={12} sm={6} lg={5}>Hi, Congratulations on being selected! We are happy to onboard you.</Grid>
      <Grid item xs={12} sm={6} lg={3}>
      <FormControl variant="outlined" className={classes.formControl} required="true"  fullWidth={true} >
            <InputLabel id="demo-simple-select-outlined-label" style={{color :'red'}}>Select Candidate Type</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={placement}
                onChange={onChangePlacement}
                label="Select Candidate Type"
                required="true"
                autoWidth="true"
                
                >
               
                    {
                        placementType.map((exp)=>{
                            return( <MenuItem value={exp.value}>{exp.name}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
            {formErrors.placement && <span  style={{color:'blue'}}>{formErrors.placement}</span>}
      </Grid>
      {
        placement && <><Grid item xs={12} sm={6} lg={3}>
        <TextField id="outlined-basic" label="First Name (As per Adhaar Card)" variant="outlined" value={First_Name} onChange={onChangesetFirst_Name} required="true" fullWidth={true} error={formErrors.firstName && <span>{formErrors.firstName}</span>}/>
        {formErrors.First_Name && <span  style={{color:'blue'}}><i>{formErrors.First_Name}</i></span>}
        </Grid>
         <Grid item xs={12} sm={6} lg={3}>
         <TextField id="outlined-basic" label="Last Name (As per Adhaar Card)" variant="outlined" value={Last_Name} onChange={onChangesetLast_Name} required="true" fullWidth={true} error={formErrors.lastName && <span>{formErrors.lastName}</span>}/>
         {formErrors.Last_Name && <span  style={{color:'blue'}}>{formErrors.Last_Name}</span>}
         </Grid>
         <Grid item xs={12} sm={6} lg={3}>
            <TextField
                  id="outlined-basic"
                  label="DOB"
                  type="date"
                  variant="outlined"
                  value = {DOB}
                  onChange={onChangesetDOB}
                  //defaultValue="2017-05-24"
                  className={classes.textField}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}/>
            {/* <TextField id="outlined-basic" label="DOB" variant="outlined" value={Dob} onChange={onChangeDOB} required="true" fullWidth={true} error={formErrors.Dob && <span>{formErrors.Dob}</span>}/>
            {formErrors.Dob && <span  style={{color:'blue'}}>{formErrors.Dob}</span>} */}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Blood Group" variant="outlined" value={Blood_Group} onChange={onChangesetBlood_Group} required="true" fullWidth={true} error={formErrors.email && <span>{formErrors.email}</span>}/><br/>
            {formErrors.Blood_Group && <span style={{color:'blue'}}> { formErrors.Blood_Group } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <FormControl variant="outlined" className={classes.formControl} required="true"  fullWidth={true} >
            <InputLabel id="demo-simple-select-outlined-label">Marital Status</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={Maritial_Status}
                onChange={onChangesetMaritial_Status}
                label="Marital status"
                required="true"
                
                >
               
                    {
                        martialStatus.map((exp)=>{
                            return( <MenuItem value={exp.value}>{exp.name}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
            {formErrors.Maritial_Status && <span  style={{color:'blue'}}>{formErrors.Maritial_Status}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <FormControl variant="outlined" className={classes.formControl} required="true"  fullWidth={true} >
            <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={Sex}
                onChange={onChangesetSex}
                label="Gender"
                required="true"
                
                >
                <MenuItem value="">
                    <em>Select gender</em>
                </MenuItem>
                    {
                        gender.map((exp)=>{
                            return( <MenuItem value={exp.value}>{exp.name}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
            {formErrors.Sex && <span  style={{color:'blue'}}>{formErrors.Sex}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Nationality" variant="outlined" value={Nationality} onChange={onChangesetNationality} required="true" fullWidth={true} error={formErrors.EmailID && <span>{formErrors.EmailID}</span>}/><br/>
            {formErrors.EmailID && <span style={{color:'blue'}}> { formErrors.Nationality } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Personal Email ID" variant="outlined" value={EmailID} onChange={onChangesetEmailID} required="true" fullWidth={true} error={formErrors.EmailID && <span>{formErrors.EmailID}</span>}/><br/>
            {formErrors.EmailID && <span style={{color:'blue'}}> { formErrors.EmailID } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}><p>Present Residential Address</p></Grid>
            <Grid item xs={12} sm={6} lg={3}>
            
            <TextField id="outlined-basic" label="Address Line 1" variant="outlined" value={pAd1} onChange={onChangesetsetPAd1} required="true" fullWidth={true} error={formErrors.pAd1 && <span>{formErrors.pAd1}</span>}/>
            
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Present Residential Address" maxWidth="true" value={Residential_Address} onChange={onChangesetResidential_Address} required={true}/><br/> */}
            {formErrors.pAd1 && <span style={{color:'blue'}}> { formErrors.pAd1 } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
            
            <TextField id="outlined-basic" label="Address Line 2" variant="outlined" value={pAd2} onChange={onChangesetPAd2} required="true" fullWidth={true} error={formErrors.pAd2 && <span>{formErrors.pAd2}</span>}/>
            
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Present Residential Address" maxWidth="true" value={Residential_Address} onChange={onChangesetResidential_Address} required={true}/><br/> */}
            {formErrors.pAd2 && <span style={{color:'blue'}}> { formErrors.pAd2 } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
            
            <TextField id="outlined-basic" label="City" variant="outlined" value={pCity} onChange={onChangesetsetpCity} required="true" fullWidth={true} error={formErrors.pCity && <span>{formErrors.pCity}</span>}/>
            
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Present Residential Address" maxWidth="true" value={Residential_Address} onChange={onChangesetResidential_Address} required={true}/><br/> */}
            {formErrors.pCity && <span style={{color:'blue'}}> { formErrors.pCity } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
            
            <TextField id="outlined-basic" label="Pincode" variant="outlined" value={pcode} onChange={onChangesetpcode} required="true" fullWidth={true} error={formErrors.pcode && <span>{formErrors.pcode}</span>}/>
            
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Present Residential Address" maxWidth="true" value={Residential_Address} onChange={onChangesetResidential_Address} required={true}/><br/> */}
            {formErrors.pcode && <span style={{color:'blue'}}> { formErrors.pcode } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}><p>Permanent Address</p></Grid>
            <Grid item xs={12} sm={6} lg={3}>
            
            <TextField id="outlined-basic" label="Address Line 1" variant="outlined" value={rAd1} onChange={onChangesetsetrAd1} required="true" fullWidth={true} error={formErrors.rAd1 && <span>{formErrors.rAd1}</span>}/>
            
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Present Residential Address" maxWidth="true" value={Residential_Address} onChange={onChangesetResidential_Address} required={true}/><br/> */}
            {formErrors.rAd1 && <span style={{color:'blue'}}> { formErrors.rAd1 } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
            
            <TextField id="outlined-basic" label="Address Line 2" variant="outlined" value={rAd2} onChange={onChangesetrAd2} required="true" fullWidth={true} error={formErrors.rAd2 && <span>{formErrors.rAd2}</span>}/>
            
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Present Residential Address" maxWidth="true" value={Residential_Address} onChange={onChangesetResidential_Address} required={true}/><br/> */}
            {formErrors.rAd2 && <span style={{color:'blue'}}> { formErrors.rAd2 } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
            
            <TextField id="outlined-basic" label="City" variant="outlined" value={rCity} onChange={onChangesetsetrCity} required="true" fullWidth={true} error={formErrors.rCity && <span>{formErrors.rCity}</span>}/>
            
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Present Residential Address" maxWidth="true" value={Residential_Address} onChange={onChangesetResidential_Address} required={true}/><br/> */}
            {formErrors.rCity && <span style={{color:'blue'}}> { formErrors.rCity } </span>}<br />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
            
            <TextField id="outlined-basic" label="Pincode" variant="outlined" value={rcode} onChange={onChangesetrcode} required="true" fullWidth={true} error={formErrors.rcode && <span>{formErrors.rcode}</span>}/>
            
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Present Residential Address" maxWidth="true" value={Residential_Address} onChange={onChangesetResidential_Address} required={true}/><br/> */}
            {formErrors.rcode && <span style={{color:'blue'}}> { formErrors.rcode } </span>}<br />
            </Grid>
            
            {/* <Grid item xs={12} sm={6} lg={3}>
            <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Permanent Address" maxWidth="true" value={Permanent_Address} onChange={onChangesetPermanent_Address} required={true}/><br/>
            {formErrors.Permanent_Address && <span style={{color:'blue'}}> { formErrors.Permanent_Address } </span>}<br />
            </Grid> */}
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Mobile Number" variant="outlined" value={Mobile_Number} onChange={onChangesetMobile_Number} required="true" fullWidth={true} error={formErrors.mobile && <span>{formErrors.mobile}</span>}/>
            {formErrors.Mobile_Number && <span  style={{color:'blue'}}>{formErrors.Mobile_Number}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" className={classes.TextField} label="Alternate Mobile" variant="outlined" value={Alternate_Number} onChange={onChangesetAlternate_Number} fullWidth={true} error={formErrors.Alternate_Number && <span>{formErrors.Alternate_Number}</span>}/> 
            {formErrors.Alternate_Number && <span  style={{color:'blue'}}>{formErrors.Alternate_Number}</span>}
            </Grid>
            {
              placement === 'Experienced' &&  <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic" label="Pan Number" variant="outlined" value={Pan_Number} onChange={onChangesetPan_Number} required="true" fullWidth={true}  error={formErrors.Pan_Number && <span>{formErrors.Pan_Number}</span>}/><br/>
              {formErrors.Pan_Number && <span  style={{color:'blue'}}>{formErrors.Pan_Number}</span>}
              </Grid>
            }
           
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Adhaar Number" variant="outlined" value={Adhaar_Number} onChange={onChangesetAdhaar_Number} required="true" fullWidth={true}  error={formErrors.Adhaar_Number && <span>{formErrors.Adhaar_Number}</span>}/><br/>
            {formErrors.Adhaar_Number && <span  style={{color:'blue'}}>{formErrors.Adhaar_Number}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Emergency Contact Number" variant="outlined" value={Emergency_Contact_Number} onChange={onChangesetEmergency_Contact_Number} required="true" fullWidth={true}  error={formErrors.Emergency_Contact_Number && <span>{formErrors.Emergency_Contact_Number}</span>}/><br/>
            {formErrors.Emergency_Contact_Number && <span  style={{color:'blue'}}>{formErrors.Emergency_Contact_Number}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            {/* <TextField id="outlined-basic" label="Backlogs" variant="outlined" value={backlogs} onChange={onChangebackloags} required="true" fullWidth/> */}
            <FormControl variant="outlined" className={classes.formControl} required="true"  fullWidth={true}  >
            <InputLabel id="demo-simple-select-outlined-label">Emergency Contact Relationship</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={Emergency_Contact_Relationship}
                onChange={onChangesetEmergency_Contact_Relationship}
                label="Emergency Contact Relationship"
                required="true"
                autoWidth ="false"
                >
                <MenuItem value="">
                    <em>Select option</em>
                </MenuItem>
                    {
                        relationship.map((log)=>{
                            return( <MenuItem value={log.value}>{log.name}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
            {formErrors.Emergency_Contact_Relationship && <span  style={{color:'blue'}}>{formErrors.Emergency_Contact_Relationship}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Father's Name" variant="outlined" value={Father_Name} onChange={onChangesetFather_Name} required="true" fullWidth={true}  error={formErrors.Father_Name && <span>{formErrors.Father_Name}</span>}/><br/>
            {formErrors.Father_Name && <span  style={{color:'blue'}}>{formErrors.Father_Name}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField
                  id="outlined-basic"
                  label="Fathers DOB"
                  type="date"
                  variant="outlined"
                  value = {Fathers_DOB}
                  onChange={onChangesetFathers_DOB}
                  //defaultValue="2017-05-24"
                  className={classes.textField}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}/>
            {/* <TextField id="outlined-basic" label="DOB" variant="outlined" value={Dob} onChange={onChangeDOB} required="true" fullWidth={true} error={formErrors.Dob && <span>{formErrors.Dob}</span>}/>
            {formErrors.Dob && <span  style={{color:'blue'}}>{formErrors.Dob}</span>} */}

            {formErrors.Fathers_DOB && <span  style={{color:'blue'}}>{formErrors.Fathers_DOB}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Mothers's Name" variant="outlined" value={Mothers_Name} onChange={onChangesetsetMothers_Name} required="true" fullWidth={true}  error={formErrors.Mothers_Name && <span>{formErrors.Mothers_Name}</span>}/><br/>
            {formErrors.Mothers_Name && <span  style={{color:'blue'}}>{formErrors.Mothers_Name}</span>}
            </Grid>
            
            <Grid item xs={12} sm={6} lg={3}>
            <TextField
                  id="outlined-basic"
                  label="Mothers DOB"
                  type="date"
                  variant="outlined"
                  value = {Mothers_DOB}
                  onChange={onChangesetMothers_DOB}
                  //defaultValue="2017-05-24"
                  className={classes.textField}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}/>
            {/* <TextField id="outlined-basic" label="DOB" variant="outlined" value={Dob} onChange={onChangeDOB} required="true" fullWidth={true} error={formErrors.Dob && <span>{formErrors.Dob}</span>}/>
            {formErrors.Dob && <span  style={{color:'blue'}}>{formErrors.Dob}</span>} */}

            {formErrors.Mothers_DOB && <span  style={{color:'blue'}}>{formErrors.Mothers_DOB}</span>}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
            <TextField id="outlined-basic" label="Offer Code" variant="outlined" value={Process_Selected_for} onChange={onChangesetProcess_Selected_for} required="true" fullWidth/> 
            {formErrors.Process_Selected_for && <span  style={{color:'blue'}}>{formErrors.Process_Selected_for}</span>}
            </Grid>
            {
              placement === 'Experienced' && <><Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic" label="Bank Account Number for Salary Credit" variant="outlined" value={Bank_Account_Number_for_Salary_Credit} onChange={onChangesetBank_Account_Number_for_Salary_Credit} required="true" fullWidth/> 
              
              {formErrors.Bank_Account_Number_for_Salary_Credit && <span  style={{color:'blue'}}>{formErrors.Bank_Account_Number_for_Salary_Credit}</span>}
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic" className={classes.TextField} label="IFSC Code" variant="outlined" value={IFSC_Code} onChange={onChangesetIFSC_Code}  required="true" fullWidth={true} error={formErrors.IFSC_Code && <span>{formErrors.IFSC_Code}</span>}/>  
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic" className={classes.TextField} label="Bank Name" variant="outlined" value={Bank_Name} onChange={onChangesetBank_Name}  required="true" fullWidth={true} error={formErrors.Bank_Name && <span>{formErrors.Bank_Name}</span>}/>  
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic" className={classes.TextField} label="PF Number" variant="outlined" value={PF_Number} onChange={onChangesetPF_Number}   fullWidth={true} />  
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic" className={classes.TextField} label="UAN Number" variant="outlined" value={UAN_Number} onChange={onChangesetUAN_Number}   fullWidth={true} />  
              </Grid>
              </>
            }
           
            <Grid item xs={12} sm={12} lg={12}><br/>Upload The Following Documents </Grid>
            {
              placement === 'Experienced' && <>
                     <Grid item xs={12} sm={12} lg={3}>
                    <FileUpload getfileName={getfileName} value="Sslc / Puc / Degree marks card"/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3}>
                      <FileUpload getfileName={getfileName} value="Pan card" location={setpancard}/>
                    </Grid>
                  
                    <Grid item xs={12} sm={12} lg={3}>
                      <FileUpload getfileName={getfileName} value="Cancelled cheque"/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={2}>  
                    <FileUpload getfileName={getfileName} value="Pay slip month 1"/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={2}>
                    
                    <FileUpload getfileName={getfileName} value="Pay slip month 2"/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={2}>
                    
                    <FileUpload getfileName={getfileName} value="Pay slip month 3"/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={2}>
                    
                    <FileUpload getfileName={getfileName} value="Relieving letter"/>
                    </Grid>
                   
              </>
            }
            {
              placement === 'Fresher'  && <>
               <Grid item xs={12} sm={12} lg={3}>
                    <FileUpload getfileName={getfileName} value="Sslc / Puc / Degree marks card"/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3}>
                      <FileUpload getfileName={getfileName} value="Pan card" location={setpancard}/>
                    </Grid>

              </>
            }
            
            <Grid item xs={12} sm={12} lg={3}>
            <FileUpload getfileName={getfileName} value="Adhar card"/>
            </Grid>
            
            <Grid item xs={12} sm={12} lg={2}>
             
            <FileUpload getfileName={getfileName} value="Photograph"/>
            </Grid>
            
            
            <Grid item xs={12} sm={12} lg={2}>
             
            <FileUpload getfileName={getfileName} value="Resume"/>
            {/* <FileUpload getfileName={getfileName}/> */}
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
            <Checkbox
        checked={declaration}
        color="secondary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        onChange={handledeclaration}
      />Declaration*
        I hereby accept that the informationn updated is true and accurate</Grid>
        <Grid item xs={12} sm={12} lg={12}>
            <Button variant="contained" color="secondary" onClick={handleSubmit} >Submit</Button>
            </Grid>
         </>
      }
            
           
           
            
            
            
            
    </Grid>
    </CardContent>
    </Card>
    </Grid>
    <Grid item xs={0} sm={1}></Grid>
    <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          style={{ width: '100%' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarMessage.severity}
          >
            {snackbarMessage.message}
          </Alert>
        </Snackbar>
    </Grid>
    <Grid><a href="https://www.grssl.com/" target="_blank" rel="noopener noreferrer">Visit Our Website</a></Grid>
    <Box mt={5}>
              <Copyright />
    </Box>
        
    </div>)
}

export default JobForm
